<template>
    <div id="actions">
        <div id="total-recap">
            <b>Totale fatture {{this.requestParams.tipologia}} {{(this.requestParams.query !== undefined
                )?'filtrate':''}}</b>:
            {{this.lastResponse.data.info.total}}
        </div>
        <div id="xlsx-export">
            <div id="xlsx-export-overlay" v-if="showExportOptions" v-on:click="showExportOptions = false">
                <div id="xlsx-export-options">
                    <div class="header">ESPORTAZIONE XLSX
                        <font-awesome-icon v-if="isXlsxLoading" icon="spinner" spin/>
                        <font-awesome-icon v-else icon="file-excel"/>
                    </div>
                    <div class="option">
                        <button v-on:click="exportCurrentResults('compact',$event)" :disabled="isXlsxLoading">Versione
                            compatta
                        </button>
                    </div>
                    <div class="option">
                        <button v-on:click="exportCurrentResults('complete',$event)" :disabled="isXlsxLoading">Versione
                            estesa
                        </button>
                    </div>
                </div>
            </div>
            <button v-on:click="showExportOptions = true">
                Esporta in Excel
                <font-awesome-icon icon="file-excel"/>
            </button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Actions",
        data() {
            return {
                isXlsxLoading: false,
                showExportOptions: false
            }
        },
        methods: {
            exportCurrentResults: function (mode, event) {
                event.stopPropagation();
                let requestParams = Object.assign({}, this.requestParams);
                if (mode === 'complete') {
                    requestParams.complete = true;
                }
                this.isXlsxLoading = true;
                this.$http.get(this.service_url + 'api/socio/fatture/export', {
                        params: requestParams,
                        headers: {
                            Authorization: 'Bearer ' + this.access_token
                        },
                        responseType: 'arraybuffer'
                    }
                ).then(response => {
                    this.isXlsxLoading = false;
                    this.showExportOptions = false;
                    let blob = new Blob([response.data], {type: response.headers['content-type']});
                    this.fileSaver.saveAs(blob, requestParams.tipologia + '_' + requestParams.anno + '.xlsx');
                }).catch(err => {
                    // eslint-disable-next-line
                    console.log(err);
                })
            }
        },
        computed: {
            requestParams() {
                return this.$store.state.fatture.params;
            },
            lastResponse() {
                return this.$store.state.fatture.lastResponse;
            },
            service_url() {
                return this.$store.state.documentale.service_url;
            },
            access_token() {
                return this.$store.state.documentale.access_token;
            }
        },
    }
</script>

<style scoped lang="scss">
    #actions {
        #total-recap {
            position: relative;
            display: inline-block;
            padding: 6px 0;
            font-family: "Open Sans";
            b {
                color: #5f6368;
                font-family: "Roboto Slab";
            }
        }
        position: relative;
        display: block;
        padding: 15px 5px;
        &:after {
            clear: both;
            content: "";
            display: block;
        }
        #xlsx-export {
            position: relative;
            display: inline-block;
            float: right;
            #xlsx-export-overlay {
                position: fixed;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                background-color: rgba(0, 0, 0, 0.4);
                z-index: 10000;
                #xlsx-export-options {
                    position: absolute;
                    height: 600px;
                    width: 350px;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background-color: transparent;
                    -webkit-transition: all ease-out 0.6s;
                    -moz-transition: all ease-out 0.6s;
                    -o-transition: all ease-out 0.6s;
                    transition: all ease-out 0.6s;
                    div {
                        &.header {
                            background-color: #248f50;
                            font-family: "Roboto Slab";
                            color: #ffffff;
                            text-align: center;
                            font-weight: bold;
                            padding: 10px;
                            svg {
                                margin-left: 5px;
                            }
                        }
                        &.option {
                            button {
                                color: #ffffff;
                                background-color: #248f50;
                                border: 1px solid #248f50;
                                font-family: "Roboto Slab";
                                width: 100%;
                                font-weight: bold;
                                margin-top: 5px;
                                padding: 10px;
                                &:hover {
                                    background-color: #ffffff;
                                    color: #248f50;
                                }
                            }
                        }
                    }
                }
            }
            button {
                font-family: "Roboto Slab";
                background-color: white;
                color: #5f6368;
                font-weight: bold;
                border: none;
                border-bottom: 2px solid transparent;
                border-radius: 0;
                svg {
                    color: #248f50;
                }
                &:hover {
                    border-bottom: 2px solid #248f50;
                }
                &:disabled {
                    cursor: not-allowed;
                    color: #5f6368;
                    border-bottom: 2px solid #5f6368;
                }
            }
        }
    }

</style>