<template>
    <div id="Fatture">
        <FattureBlock v-if="fattureLoading" v-bind:FattureBlockStatus="FattureBlockStatus"></FattureBlock>
        <transition name="fade">
            <InvoiceOptions v-if="invoiceOptionsIndex !== null"></InvoiceOptions>
        </transition>
        <transition name="fade">
            <InvoiceFilters v-if="showInvoiceFilters"></InvoiceFilters>
        </transition>
        <ControlCenter v-if="requestParams.tipologia !== null"></ControlCenter>
        <template v-if="lastResponse !== null">
            <template v-if="showedInvoices.length !== 0">
                <Actions></Actions>
                <Tabella :fatture="fatture"></Tabella>
                <Pagination :lastResponse="lastResponse"></Pagination>
            </template>
            <template v-else-if="requestParams.query === undefined">
                <div class="no-content" style="text-align:center">
                    <font-awesome-icon icon="exclamation-circle" size="6x"/>
                    <p>Non sono presenti fatture {{requestParams.tipologia}} per l'anno {{requestParams.anno}}.</p>
                </div>
            </template>
            <template v-else>
                <div class="no-content" style="text-align:center">
                    <font-awesome-icon icon="exclamation-circle" size="6x"/>
                    <p>Nessuna delle tue fatture {{requestParams.tipologia}} soddisfa i filtri impostati.</p>
                    <p><span v-on:click="resetFilters">RESETTA I FILTRI</span> oppure fai
                        click sull'icona dei filtri
                        nella casella di ricerca per impostarli diversamente</p>
                </div>
            </template>
        </template>
    </div>
</template>

<script>
    import ControlCenter from '../components/Fatture/ControlCenter.vue'
    import Tabella from '../components/Fatture/Tabella.vue'
    import Pagination from '../components/Fatture/Pagination.vue'
    import InvoiceOptions from '../components/Fatture/InvoiceOptions.vue';
    import InvoiceFilters from '../components/Fatture/InvoiceFilters.vue';
    import Actions from '../components/Fatture/Actions.vue';
    import FattureBlock from '../components/ui/FattureBlock.vue';

    export default {
        name: "Fatture",
        components: {
            ControlCenter, Tabella, Pagination, InvoiceOptions, InvoiceFilters, Actions, FattureBlock
        },
        data() {
            return {
                FattureBlockStatus: {error: false, status: null},
                fattureLoading: false
            }
        },
        methods: {
            resetFilters: function () {
                let newRequestParams = this.requestParams;
                delete newRequestParams.query;
                this.$store.commit('fatture/setParams', Object.assign({}, newRequestParams));
            },
            getFatture: function () {
                let requestParams = this.requestParams;
                return this.$http.get(this.service_url + 'api/socio/fatture', {
                        params: requestParams,
                        headers: {
                            Authorization: 'Bearer ' + this.access_token
                        }
                    }
                )
            },
            updateFatture: function () {
                this.fattureLoading = true;
                this.getFatture().then(response => {
                    this.$store.commit('fatture/setLastResponse', response);
                    this.fattureLoading = false;
                }).catch(err => {
                    let status = '';
                    try {
                        status = err.response.status;
                    } catch (e) {
                        status = 500;
                    }
                    switch (status) {
                        case 404:
                            this.FattureBlockStatus = {error: true, status: 404};
                            setTimeout(() => {
                                this.$router.push({path: '/'})
                            }, 3000);
                            break;
                        case 424:
                            this.FattureBlockStatus = {error: true, status: 424};
                            break;
                        case 401:
                            this.FattureBlockStatus = {error: true, status: 401};
                            break;
                        case 400:
                            this.FattureBlockStatus = {error: true, sattus: 400};
                            setTimeout(this.$router.push({path: '/'}), 3000);
                            break;
                        case 500:
                            this.FattureBlockStatus = {error: true, status: 500};
                            break;
                    }
                })
            }
        },
        watch: {
            requestParams(to, from) {

                if (to.anno !== from.anno || to.tipologia !== from.tipologia) {
                    if (to.anno !== null) {
                        this.$router.push({
                            name: 'fatture',
                            params: {tipologia: to.tipologia, anno: to.anno}
                        }).catch(() => {
                        })
                    } else {
                        this.$router.push({name: 'fatture', params: {tipologia: to.tipologia}}).catch(() => {
                        })
                    }
                }
                if (to.anno !== null && to.tipologia !== null) {
                    this.updateFatture();
                }
            },
            lastResponse(to) {
                this.fatture = to.data.items
            }
        },
        mounted() {
            let tipologia = this.$route.params.tipologia;
            let anno = this.$route.params.anno;
            if (tipologia !== 'attive' && tipologia !== 'passive') {
                tipologia = 'attive'
            }
            if (anno === undefined) {
                anno = this.moment().year();
            }
            this.$router.push({name: 'fatture', params: {tipologia: tipologia, anno: anno}});
            this.$store.commit('fatture/setParams', Object.assign({}, {
                tipologia: tipologia,
                anno: anno,
                limit: 25,
                page: 1,
                orderBy: 'dataPrevista',
                order: 'DESC'
            }));
        },
        computed: {
            service_url() {
                return this.$store.state.documentale.service_url;
            },
            access_token() {
                return this.$store.state.documentale.access_token;
            },
            requestParams() {
                return this.$store.state.fatture.params;
            },
            lastResponse() {
                return this.$store.state.fatture.lastResponse;
            },
            invoiceOptionsIndex() {
                return this.$store.state.fatture.invoiceOptionsIndex;
            },
            showInvoiceFilters() {
                return this.$store.state.fatture.showInvoiceFilters;
            },
            showedInvoices() {
                return this.$store.state.fatture.showedInvoices;
            }
        }
    }
</script>

<style scoped lang="scss">
    #Fatture {
        padding: 10px;
    }

    .no-content {
        margin: 5% 0;
        padding: 15px;
        svg {
            margin-bottom: 15px;
            color: #248f50;
        }
        p {
            font-family: "Roboto Slab";
            span {
                text-decoration: underline;
                cursor: pointer;
                color: #248f50;
                font-weight: 700;
            }
        }
    }
</style>