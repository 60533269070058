<template>
    <div id="invoiceEditWrapper">
        <div id="invoiceEdit">
            <InvoiceEditBLock v-if="isUpdating"></InvoiceEditBLock>
            <InvoicePayment v-if="showInvoicePaymentOptions"
                            v-bind:editedInvoiceData="editedInvoiceData"
                            v-bind:invoicePaymentToEdit="invoicePaymentToEdit"
                            v-on:add="addPayment" v-on:modify="modifyPayment"
                            v-on:closePaymentOptions="showInvoicePaymentOptions = false"></InvoicePayment>
            <div id="movimentazioni">
                <div id="movimentazioni-control">
                    <span>Movimentazioni</span>
                    <button id="add-movimentazione" v-if="editedInvoiceData.residuo !== 0"
                            v-on:click="invoicePaymentOptions(null)">
                        Aggiungi
                        <font-awesome-icon icon="plus"/>
                    </button>
                    <button v-if="editedInvoiceData.residuo !== 0" v-on:click="fullfillPayment">
                        {{(tipologiaDaVisualizzare === 'attive')?'Incassata':'Pagata'}}
                        <font-awesome-icon icon="check-square"/>
                    </button>
                </div>
                <table id="movimentazioni-table">
                    <thead>
                    <tr>
                        <th>Data</th>
                        <th>Importo</th>
                        <th>Discriminante</th>
                        <th>Note</th>
                        <th>Azioni</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr :key="index" v-for="(movimentazione, index) in editedInvoiceData.movimentazioni ">
                        <td>{{moment(movimentazione.data).format('L')}}</td>
                        <td>{{movimentazione.importo.toFixed(2)}} €</td>
                        <td>{{movimentazione.metodo}}</td>
                        <td>{{movimentazione.note}}</td>
                        <td>
                            <font-awesome-icon icon="edit"
                                               v-on:click="invoicePaymentOptions({index:index,payment:movimentazione})"/>
                            <font-awesome-icon icon="trash-alt" v-on:click="removePayment(index)"/>
                        </td>
                    </tr>
                    <tr class="noPayments" v-if="editedInvoiceData.movimentazioni.length === 0">
                        <td colspan="5">
                            Non sono presenti movimentazioni <span v-on:click="invoicePaymentOptions(null)">aggiungine una</span>
                            o imposta la fattura come
                            <span v-on:click="fullfillPayment">{{(tipologiaDaVisualizzare === 'attive')?'incassata':'pagata'}}</span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div id="recap">
                <span><b>Parziale {{(tipologiaDaVisualizzare === 'attive')?'incassato':'pagato'}}: </b>{{editedInvoiceData.parziale.toFixed(2)}}€</span>
                <span><b>Residuo {{(tipologiaDaVisualizzare === 'attive')?'da incassare':'da pagare'}}: </b>{{editedInvoiceData.residuo.toFixed(2)}}€</span>
            </div>

            <div>
                <div class="invoiceEditDates">
                    <label>Data Prevista {{(tipologiaDaVisualizzare === 'attive')?'incasso':'pagamento'}}:</label>
                    <flat-pickr
                            v-on:click="$event.preventDefault()"
                            v-model="editedInvoiceData.dataPrevista"
                            :config="dataPrevistaConfig"
                            class="text-filter"
                            placeholder="Inserire la data prevista del pagamento"
                            id="dataPrevista"
                    >
                    </flat-pickr>
                </div>
                <div class="invoiceEditDates">
                    <label>Data Effettiva {{(tipologiaDaVisualizzare ===
                        'attive')?'incasso':'pagamento'}}:</label>
                    <flat-pickr
                            v-on:click="$event.preventDefault()"
                            v-model="editedInvoiceData.dataEffettiva"
                            :config="dataEffettivaConfig"
                            class="text-filter"
                            placeholder="Inserire la data effettiva del pagamento"
                            id="dataEffettiva"
                    >
                    </flat-pickr>
                </div>
            </div>
            <div>
                <label>Note:</label>
                <textarea rows="5" v-model="editedInvoiceData.note"></textarea>
            </div>
        </div>
        <button id="UpdateInvoice" :disabled="updateDisabled" v-on:click="updateInvoice">Salva</button>
    </div>
</template>
<script>
    import {Italian} from "flatpickr/dist/l10n/it.js"
    import InvoiceEditBLock from '../ui/InvoiceEditBlock.vue';
    import InvoicePayment from './InvoicePayment.vue';

    export default {
        name: "InvoiceEdit",
        components: {InvoiceEditBLock, InvoicePayment},
        data() {
            return {
                haveToUpdate: false,
                invoicePaymentToEdit: null,
                showInvoicePaymentOptions: false,
                isUpdating: false,
                editedInvoiceData: {
                    note: null,
                    dataPrevista: null,
                    dataEffettiva: null,
                    parziale: 0,
                    residuo: 0,
                    saldata: 0,
                    movimentazioni: []
                },
                updateDisabled: true,
                dataPrevistaConfig: {
                    altFormat: 'd/m/Y',
                    altInput: true,
                    dateFormat: 'Y-m-d H:i:S',
                    locale: Italian,
                    monthSelectorType: 'static',
                    position: "below"
                },
                dataEffettivaConfig: {
                    altFormat: 'd/m/Y',
                    altInput: true,
                    dateFormat: 'Y-m-d H:i:S',
                    locale: Italian,
                    monthSelectorType: 'static',
                    position: "below"
                }
            }
        },
        watch: {
            editedInvoiceData: {
                handler(to) {
                    if (to.dataEffettiva === '') {
                        to.dataEffettiva = null;
                    }
                    if (to.dataPrevista === '') {
                        to.dataPrevista = null;
                    }
                    if (to.note === '') {
                        to.note = null;
                    }
                    let parziale = 0;
                    to.movimentazioni.forEach(movimentazione => {
                        parziale += movimentazione.importo;
                    });
                    to.parziale = parziale;
                    to.residuo = this.invoice.Totale - parziale;
                    to.saldata = (to.residuo === 0);
                    if (this.haveToUpdate || to.dataPrevista !== this.invoice.dataPrevista || to.dataEffettiva !== this.invoice.dataEffettiva || to.parziale !== this.invoice.parziale || to.note !== this.invoice.note || to.saldata !== this.invoice.saldata || JSON.stringify(to.movimentazioni) !== JSON.stringify(this.invoice.movimentazioni)) {
                        this.updateDisabled = false;
                    } else {
                        this.updateDisabled = true;
                    }
                },
                deep: true
            }
        },
        mounted() {
            this.editedInvoiceData.parziale = this.invoice.parziale;
            this.editedInvoiceData.residuo = this.invoice.residuo;
            this.editedInvoiceData.note = this.invoice.note;
            this.editedInvoiceData.dataPrevista = this.invoice.dataPrevista;
            this.editedInvoiceData.dataEffettiva = this.invoice.dataEffettiva;
            this.editedInvoiceData.saldata = this.invoice.saldata;
            this.editedInvoiceData.movimentazioni = (this.invoice.movimentazioni !== undefined) ? this.invoice.movimentazioni : [];
        },
        computed: {
            invoice() {
                return this.$store.state.fatture.showedInvoices[this.$store.state.fatture.invoiceOptionsIndex];
            },
            service_url() {
                return this.$store.state.documentale.service_url;
            },
            access_token() {
                return this.$store.state.documentale.access_token;
            },
            tipologiaDaVisualizzare() {
                return this.$store.state.fatture.tipologiaDaVisualizzare;
            }
        },
        methods: {
            invoicePaymentOptions: function (index) {
                this.showInvoicePaymentOptions = true;
                this.invoicePaymentToEdit = index;
            },
            fullfillPayment: function () {
                this.editedInvoiceData.movimentazioni.push({
                    data: this.moment().format('YYYY-MM-DD HH:mm:ss'),
                    note: null,
                    metodo: null,
                    importo: this.editedInvoiceData.residuo
                });
                this.showInvoicePaymentOptions = true;
                let justCreatedIndex = this.editedInvoiceData.movimentazioni.length - 1;
                this.invoicePaymentToEdit = {index: justCreatedIndex, payment: this.editedInvoiceData.movimentazioni[justCreatedIndex]};
            },
            updateInvoice: function () {
                this.isUpdating = true;
                let requestBody = this.editedInvoiceData;
                requestBody._id = this.invoice._id;
                return this.$http.put(this.service_url + 'api/socio/fatture',
                    requestBody, {
                        headers: {
                            Authorization: 'Bearer ' + this.access_token
                        }
                    }
                ).then(response => {
                    this.haveToUpdate = false;
                    this.isUpdating = false;
                    this.$set(this, 'editedInvoiceData', response.data);
                    this.editedInvoiceData = Object.assign({}, response.data);
                    this.$store.commit('fatture/updateShowedInvoice', Object.assign(this.invoice, response.data));
                })
            },
            addPayment: function (payment) {
                this.editedInvoiceData.movimentazioni.push(payment);
                this.showInvoicePaymentOptions = false;
            },
            modifyPayment: function (payment) {
                this.$set(this.editedInvoiceData.movimentazioni, payment.index, {
                    data: payment.data,
                    note: payment.note,
                    metodo: payment.metodo,
                    importo: payment.importo
                });
                this.haveToUpdate = true;
                this.showInvoicePaymentOptions = false;
            },
            removePayment: function (index) {
                this.editedInvoiceData.movimentazioni.splice(index, 1);
            }
        }
    }
</script>

<style lang="scss">

    .invoiceEditDates {
        margin-bottom: 20px;
        width: calc(50% - 10px);
        float: left;
        &:first-of-type {
            padding-right: 10px;
        }
        &:last-of-type {
            padding-left: 10px;
        }
    }

    #UpdateInvoice {
        background-color: #248f50;
        color: white;
        font-family: "Roboto Slab";
        width: 100%;
        font-weight: bold;
        border: none;
        padding: 12px;
        &:disabled {
            background-color: grey;
            cursor: not-allowed;
        }
    }

    #invoiceEditWrapper {
        position: relative;
        width: 100%;
    }

    #invoiceEdit {
        position: relative;
        padding: 15px;
        #dataPrevista, #dataEffettiva {
            width: 100%
        }
        label {
            font-size: 0.85em;
            color: #5f6368;
            font-family: "Roboto Slab";
        }
        .text-filter:focus, .text-filter:hover {
            border: 1px dashed transparent;
            box-shadow: inset 0 -2px 0 0 #248f50;
            border-top: none;
        }
        .text-filter:focus {
            outline: none;
        }

        .text-filter {
            color: #202124;
            font-size: 13px;
            font-weight: 500;
            background: transparent;
            border-radius: 0;
            border: 1px solid transparent;
            border-top: none;
            box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, .12);
            box-sizing: border-box;
            display: inline-block;
            overflow: hidden;
            padding: 0 12px 2px;
            vertical-align: middle;
            min-height: 32px;
            width: 100%;
            height: 25px;
            line-height: normal;
            white-space: nowrap;
            font-family: "Roboto Slab";
        }
        .flatpickr-wrapper {
            width: 100%;
        }
        background-color: white;
        margin: 5px 0;
    }

    #movimentazioni-table {
        font-size: 0.8em;
        margin-bottom: 10px;
        thead {
            tr {
                border-bottom: 1px solid #92969b;
                background-color: #f0f0f0;
                font-size: 1.1em;
                color: black;

                th {
                    font-size: 0.85em;
                    font-family: "Roboto Slab";
                    color: #5f6368;
                    text-align: center;
                    padding: 0.5em 25px;
                    font-weight: bold;
                    position: relative;
                }
            }
        }
        tbody {
            tr {
                border-bottom: 1px solid #dddddd;
                font-size: 0.9em;
                -webkit-transition: all cubic-bezier(.19, 1, .22, 1) 1s;
                -moz-transition: all cubic-bezier(.19, 1, .22, 1) 1s;
                -o-transition: all cubic-bezier(.19, 1, .22, 1) 1s;
                transition: all cubic-bezier(.19, 1, .22, 1) 1s;
                &:last-of-type {
                    border-bottom: none;
                }
                &:hover {
                    background-color: #dddddd;
                }
                td {
                    svg {
                        margin: 0 5px;
                        color: #248f50;
                        cursor: pointer;
                    }
                    font-family: "Open Sans";
                    text-align: center;
                    padding: 0.5em;
                }
                &.noPayments {
                    font-family: "Roboto Slab";
                    font-size: 1.5rem;
                    &:hover {
                        background-color: #ffffff;
                    }
                    span {
                        cursor: pointer;
                        font-weight: bold;

                        color: #248f50;
                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }

    #recap {
        margin-bottom: 10px;

        span {
            font-size: 0.85em;
            display: inline-block;
            color: #5f6368;
            font-family: "Roboto Slab";
            width: 50%;
        }
    }

    #movimentazioni {

        #movimentazioni-control {
            margin-bottom: 10px;
            span {
                font-size: 0.85em;
                color: #5f6368;
                font-family: "Roboto Slab";
                padding: 5px;
                font-weight: bold;
            }
            button {
                font-size: 0.85em;
                padding: 5px;
                float: right;
                border: none;
                background-color: #ffffff;
                font-family: "Open Sans";
                font-weight: bold;
                color: #248f50;
                border-radius: 0;
                &:hover {
                    color: #ffffff;
                    background-color: #248f50;
                }
            }
        }
    }

</style>