<template>
    <div>
        <div id="invoicePagination">
            <button :disabled="lastResponse.data.info.current_page === 1" v-on:click="goToPage(1)">
                <font-awesome-icon icon="fast-backward"/>
            </button>
            <button :disabled="lastResponse.data.info.current_page === 1"
                    v-on:click="goToPage(lastResponse.data.info.current_page - 1)">
                <font-awesome-icon icon="backward"/>
            </button>
            {{lastResponse.data.info.current_page}} /
            {{lastResponse.data.info.last_page}}
            <button :disabled="lastResponse.data.info.current_page === lastResponse.data.info.last_page"
                    v-on:click="goToPage( lastResponse.data.info.current_page +1)">
                <font-awesome-icon icon="forward"/>
            </button>
            <button :disabled="lastResponse.data.info.current_page === lastResponse.data.info.last_page"
                    v-on:click="goToPage( lastResponse.data.info.last_page)">
                <font-awesome-icon icon="fast-forward"/>
            </button>
        </div>
        <div id="recap">
            Da {{lastResponse.data.info.from}} a {{lastResponse.data.info.to}} di
            {{lastResponse.data.info.total}}
        </div>
    </div>
</template>

<script>
    export default {
        name: "Pagination",
        props:['lastResponse'],
        methods:{
            goToPage:function(pageNumber){
                this.$store.commit('fatture/setParams', Object.assign({},this.$store.state.fatture.params, {
                    page: pageNumber
                }));
            }
        }
    }
</script>

<style scoped lang="scss">
    #invoicePagination {
        padding-left: 3px;
        display: inline-block;
        button {
            padding: 0;
            border: 0;
            background-color: #ffffff;
            svg {
                color: #248f50;
                margin: 0 3px;
                cursor: pointer;
            }
        }
        button:disabled:hover {
            text-decoration: none;
            color: #000000;
            cursor: not-allowed;
            svg {
                color: #000000;
                cursor: not-allowed;
            }
        }

    }
    #recap {
        float: right;
        font-family: "Roboto Slab";
        padding-right: 3px;
    }

</style>