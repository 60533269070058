<template>
    <div id="invoiceOptionsOverlay" v-on:click="$store.commit('fatture/hideInvoiceOptions')">
        <div id="invoiceOptions" v-on:click="$event.stopPropagation()"
             v-bind:class="{wide : invoicePreviewShow === true, wideEdit : invoiceEditShow === true}">
            <div id="invoiceOptionsControl">
                <div id="closeInvoiceOptions"
                     v-on:click="$store.commit('fatture/hideInvoiceOptions')" key="closeAll">
                    <span>CHIUDI</span>
                    <font-awesome-icon icon="times"/>
                </div>
                <transition name="fade">
                    <div v-if="invoicePreviewShow !== false || attachmentPreviewShow !== false || invoiceEditShow !== false "
                         id="backInvoiceOptions"
                         v-on:click="closePanels"
                         key="closeInvoicePreview">
                        <font-awesome-icon icon="chevron-left"/>
                        <span>INDIETRO</span>
                    </div>
                </transition>
            </div>
            <div id="invoiceOptionsContent">
                <transition name="fade" mode="out-in" v-on:after-leave="animateInvoicePreview">
                    <template
                            v-if="invoicePreviewShow === false && attachmentPreviewShow === false && invoiceEditShow === false">
                        <div id="invoiceOptionsMenu">
                            <button v-on:click="invoiceEdit(invoice)">
                                <font-awesome-icon icon="edit"/>
                                Modifica
                            </button>
                            <button v-on:click="invoicePreview(invoice)">
                                <transition name="fade" mode="out-in">
                                    <font-awesome-icon v-if="invoicePreviewLoading"
                                                       class="ui-block-icon" icon="spinner" spin/>
                                    <font-awesome-icon v-else icon="eye"/>
                                </transition>
                                Visualizza
                            </button>
                            <button v-on:click="downloadInvoicePdf(invoice)">
                                <transition name="fade" mode="out-in">
                                    <font-awesome-icon v-if="invoicePdfDownloading"
                                                       class="ui-block-icon" icon="spinner" spin/>
                                    <font-awesome-icon icon="file-pdf"/>
                                </transition>
                                Scarica pdf
                            </button>
                            <button v-on:click="downloadInvoiceXml(invoice)">
                                <transition name="fade" mode="out-in">
                                    <font-awesome-icon v-if="invoiceXmlDownloading"
                                                       class="ui-block-icon" icon="spinner" spin/>
                                    <font-awesome-icon icon="file-invoice"/>
                                </transition>
                                Scarica xml
                            </button>
                            <template v-if="invoice.Allegati !== undefined && invoice.Allegati !== null">
                                <button v-on:click="attachmentPreviewShow = true">
                                    <font-awesome-icon icon="paperclip"/>
                                    Allegati
                                </button>
                            </template>
                        </div>
                    </template>
                    <template v-else-if="invoicePreviewShow === true">
                        <div id="invoicePreview" ref="stocazzo" v-html="invoiceDocument">

                        </div>
                    </template>
                    <template v-else-if="attachmentPreviewShow === true">
                        <button class="attachmentDownload" v-for="allegato in invoice.Allegati"
                                :key="allegato.NomeAttachment"
                                v-on:click="downloadInvoiceAttachment(invoice._id,allegato.NomeAttachment)">
                            {{allegato.NomeAttachment}}
                        </button>
                    </template>
                    <template v-else-if="invoiceEditShow === true">
                        <InvoiceEdit></InvoiceEdit>
                    </template>
                </transition>
            </div>
        </div>
    </div>
</template>

<script>
    import InvoiceEdit from './InvoiceEdit.vue';

    export default {
        name: "InvoiceOptions",
        components: {InvoiceEdit},
        data() {
            return {
                invoicePreviewLoading: false,
                invoicePreviewShow: false,
                invoiceEditShow: false,
                invoiceIframeShow: false,
                invoicePdfDownloading: false,
                invoiceXmlDownloading: false,
                attachmentPreviewShow: false,
                showInvoicesFilters: false,
                invoiceDocument: false
            }
        },
        computed: {
            invoice() {
                return this.$store.state.fatture.showedInvoices[this.$store.state.fatture.invoiceOptionsIndex];
            },
            service_url() {
                return this.$store.state.documentale.service_url;
            },
            soggettoDaVisualizzare() {
                return this.$store.state.fatture.soggettoDaVisualizzare;
            },
            access_token() {
                return this.$store.state.documentale.access_token;
            }
        },
        methods: {
            closePanels: function () {
                this.invoicePreviewShow = false;
                this.attachmentPreviewShow = false;
                this.invoiceEditShow = false
            },
            animateInvoicePreview: function () {
                setTimeout(() => {
                    this.invoiceIframeShow = !this.invoiceIframeShow;
                }, 200);
            },
            invoiceEdit() {
                this.invoiceEditShow = true;
            },
            invoicePreview: function (invoice) {
                let Istance = this;
                this.invoicePreviewLoading = true;
                this.invoicePreviewShow = false;
                this.downloadInvoiceHtml(invoice).then(response => {
                    Istance.invoiceDocument = response.data;
                    Istance.invoicePreviewShow = true;
                    Istance.invoicePreviewLoading = false;
                }).catch(() => {

                })
            },
            downloadInvoiceHtml: function (invoice) {
                let body = {_id: invoice._id, format: 'html-preview'};
                let Istance = this;
                return this.$http.get(Istance.service_url + 'api/socio/fatture/download', {
                    params: body,
                    headers: {
                        'Authorization': 'Bearer ' + this.access_token
                    }
                });
            },
            downloadInvoicePdf: function (invoice) {
                let body = {_id: invoice._id, format: 'pdf'};
                let Istance = this;
                Istance.invoicePdfDownloading = true;
                this.$http.get(Istance.service_url + 'api/socio/fatture/download', {
                    params: body,
                    headers: {
                        'Authorization': 'Bearer ' + this.access_token
                    },
                    responseType: 'arraybuffer'
                }).then(response => {
                    Istance.invoicePdfDownloading = false;
                    let blob = new Blob([response.data], {type: response.headers['content-type']});
                    this.fileSaver.saveAs(blob, invoice[Istance.soggettoDaVisualizzare].PartitaIva + '_' + invoice.Data + '_' + invoice[Istance.soggettoDaVisualizzare].alias + '.pdf');
                });
            },
            downloadInvoiceXml: function (invoice) {
                let body = {_id: invoice._id, format: 'xml'};
                let Istance = this;
                Istance.invoiceXmlDownloading = true;
                this.$http.get(Istance.service_url + 'api/socio/fatture/download', {
                    params: body,
                    headers: {
                        'Authorization': 'Bearer ' + this.access_token
                    },
                    responseType: 'arraybuffer'
                }).then(response => {
                    Istance.invoiceXmlDownloading = false;
                    let blob = new Blob([response.data], {type: response.headers['content-type']});
                    this.fileSaver.saveAs(blob, invoice[Istance.soggettoDaVisualizzare].PartitaIva + '_' + invoice.Data + '_' + invoice[Istance.soggettoDaVisualizzare].alias + '.xml');
                });
            },
            downloadInvoiceAttachment: function (invoiceId, NomeAttachment) {
                let body = {_id: invoiceId, NomeAttachment: NomeAttachment};
                let Istance = this;
                Istance.invoiceXmlDownloading = true;
                this.$http.get(Istance.service_url + 'api/socio/fatture/download', {
                    params: body,
                    headers: {
                        'Authorization': 'Bearer ' + this.access_token
                    },
                    responseType: 'arraybuffer'
                }).then(response => {
                    Istance.invoicePdfDownloading = false;
                    let blob = new Blob([response.data], {type: response.headers['content-type']});
                    this.fileSaver.saveAs(blob, Istance.getContentDispositionFilename(response.headers['content-disposition']));
                });
            },
            getContentDispositionFilename(contentDispositionHeader) {
                if (contentDispositionHeader.indexOf('attachment') !== -1) {
                    let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    let matches = filenameRegex.exec(contentDispositionHeader);
                    if (matches !== null && matches[1]) {
                        return matches[1].replace(/['"]/g, '');
                    }
                }
                return null;
            },
        }
    }
</script>

<style lang="scss">
    #invoiceOptionsOverlay {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 10000;
        #invoiceOptions {
            position: absolute;
            height: 600px;
            width: 350px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: transparent;
            -webkit-transition: all ease-out 0.6s;
            -moz-transition: all ease-out 0.6s;
            -o-transition: all ease-out 0.6s;
            transition: all ease-out 0.6s;
            &.wide {
                width: 80%;
                max-width: 1280px;
                height: 80vh;
            }
            &.wideEdit {
                width: 700px;
            }
            #invoiceOptionsControl {
                background-color: #248f50;
                &:after {
                    content: '';
                    display: block;
                    clear: both;
                }
                #closeInvoiceOptions {
                    color: #ffffff;
                    padding: 10px;
                    font-family: "Roboto Slab";
                    font-weight: bold;
                    cursor: pointer;
                    float: right;
                    span {
                        padding-right: 10px;
                    }
                }
                #backInvoiceOptions {
                    color: #ffffff;
                    padding: 10px;
                    font-family: "Roboto Slab";
                    font-weight: bold;
                    cursor: pointer;
                    span {
                        padding-left: 10px;
                    }
                }
            }
            #invoiceOptionsContent {
                width: 100%;
                -webkit-transition: all ease-out 1s;
                -moz-transition: all ease-out 1s;
                -o-transition: all ease-out 1s;
                transition: all ease-out 1s;
                #invoiceOptionsMenu {
                    button {
                        font-size: 1.2em;
                        background-color: #248f50;
                        color: #ffffff;
                        display: block;
                        width: 100%;
                        border: 0;
                        border-radius: 0;
                        margin: 5px 0;
                        padding: 20px;
                        font-family: "Roboto Slab";
                        font-weight: bold;
                        text-transform: uppercase;
                        -webkit-transition: all cubic-bezier(.19, 1, .22, 1) 1s;
                        -moz-transition: all cubic-bezier(.19, 1, .22, 1) 1s;
                        -o-transition: all cubic-bezier(.19, 1, .22, 1) 1s;
                        transition: all cubic-bezier(.19, 1, .22, 1) 1s;
                        &:hover {
                            background-color: #ffffff;
                            color: #248f50
                        }
                        svg {
                            margin-right: 5px;
                        }
                    }
                }
                #invoicePreview {
                    position: absolute;
                    top: 44px;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    max-width: 1280px;
                    padding: 15px;
                    background-color: #ffffff;
                    border: 0;
                    overflow-y: auto;

                }
            }
        }
    }

    .attachmentDownload {
        word-break: break-all;
        font-size: 1.2em;
        background-color: #248f50;
        color: #ffffff;
        display: block;
        width: 100%;
        border: 0;
        border-radius: 0;
        margin: 5px 0;
        padding: 20px;
        font-family: "Roboto Slab";
        font-weight: bold;
        text-transform: uppercase;
        -webkit-transition: all cubic-bezier(.19, 1, .22, 1) 1s;
        -moz-transition: all cubic-bezier(.19, 1, .22, 1) 1s;
        -o-transition: all cubic-bezier(.19, 1, .22, 1) 1s;
        transition: all cubic-bezier(.19, 1, .22, 1) 1s;
        &:hover {
            background-color: #ffffff;
            color: #248f50
        }
        svg {
            margin-right: 5px;
        }
    }

    @page {
        margin: 0.5%;
    }

    #fattura-elettronica {
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        margin-left: auto;
        margin-right: auto;
        /*max-width: 1280px;
        min-width: 800px;*/
        padding: 0;
    }

    #fattura-elettronica {

        div.page {
        }

        .tbHeader {
            width: 100%;
            border: 2px solid black;
        }

        .tdHead {
            width: 50%;
            height: 91px;
            border: 1px solid black;
        }

        .tableHead {
            font-size: smaller;
            width: 100%;
        }

        .headBorder {

        }

        .headerLabel {
            color: #282828;
            font-weight: bold;

        }

        .headContent {
            margin-left: 10px;
            margin-bottom: 0px
        }

        .mt5 {
            margin-top: 5px
        }

        tr.break {
            page-break-after: always;
        }

        .ulAllegati {
            margin-top: 0px;
        }

        .separa {
            height: 20px;
        }
        //Normalizzazione attributi per rispettare aspetto foglio di stile
        table {
            margin: 0;
            border-spacing: 2px;
            border-collapse: unset;
            tr td {
                vertical-align: top;
            }
            tbody {

            }
        }
        table.tbTitolo {
            width: 100%;
            table-layout: fixed;
            border-collapse: collapse;
            word-wrap: normal;
        }

        table.tbTitolo th {
            padding-left: 5px;
            padding-right: 5px;
            border: solid 1px #000000;
            border-bottom: none;
            background-color: LightCyan;
            font-size: x-small;

        }

        table.tbFoglio {
            width: 100%;
            table-layout: fixed;
            border-collapse: collapse;
            word-wrap: break-word;
        }

        table.tbFoglio th {
            padding-left: 5px;
            padding-right: 5px;
            border: solid 1px #000000;
            background-color: LightGrey;

            font-size: x-small;

        }

        table.tbFoglio tbody {
            border: solid 1px #000000;
        }

        table.tbFoglio th .perc {
            width: 50px;
        }

        table.tbFoglio td {
            font-size: small;

            border-right: solid 1px #000000;
            border-bottom: none;
            border-left: solid 1px #000000;
        }

        table.tbFoglio tr {

        }

        .textRight {
            text-align: right;
        }

        .textCenter {
            text-align: center;
        }

        .textPerc {
            width: 50px;
        }

        td.Ritenuta {
            width: 50px;
            text-align: center;
        }

        th.title, .title td {
            width: 48%
        }

        th.perc {
            /*width:50px;*/
        }

        th.perc2 {
            /*width:40px;*/
        }

        th.data {
            /*width:80px;*/
        }

        th.import {
            /*width:100px;*/
        }

        td.import {
            text-align: right;
        }

        th.import2 {
            /*width:80px;*/
        }

        td.import2 {
            text-align: right;
        }

        th.ximport {
            /*width:100px;*/
        }

        td.ximport {
            text-align: center;
        }

        th.ximport2 {
            /*width:80px;*/
        }

        td.ximport2 {
            text-align: center;
        }

        td.data {
            text-align: center;
        }

        .tx-xsmall {
            font-size: x-small;
        }

        .tx-small {
            font-size: small;
        }

        .import {
            text-align: right;
        }
    }
</style>