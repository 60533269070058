<template>
    <div id="invoice-payment-overlay">
        <div id="InvoicePayment">
            <div id="invoice-payment-form">
                <div v-on:mouseover="dateTooltipConfig.show = false">
                    <label for="data-movimento" v-tooltip="dateTooltipConfig">Data Movimento: </label>
                    <flat-pickr
                            v-on:click="$event.preventDefault()"
                            v-model="payment.date"
                            :config="paymentDateConfig"
                            class="text-filter"
                            placeholder="Inserire la data del movimento"
                            id="data-movimento"
                    >
                    </flat-pickr>
                </div>
                <div v-on:mouseover="amountTooltipConfig.show = false">
                    <label for="importo-movimento" v-tooltip="amountTooltipConfig">Importo Movimento:</label>
                    <input class="text-filter" id="importo-movimento" type="number"
                           v-model="payment.amount" placeholder="Inserire l'importo del movimento">
                </div>
                <div>
                    <label>Metodo Movimento:</label>
                    <div class="custom-select">
                        <div class="selected-value"
                             v-on:click="showInvoicePaymentMethodDropdown = !showInvoicePaymentMethodDropdown">
                            <template v-if="payment.method === null">
                                <span>GENERICO</span>
                            </template>
                            <template v-else>
                                <span>{{payment.method}}</span>
                            </template>
                            <font-awesome-icon icon="angle-down"/>
                        </div>
                        <transition name="fade">
                            <template v-if="showInvoicePaymentMethodDropdown">
                                <div class="dropdown">
                                    <div v-on:click="setInvoicePaymentMethod(null)">GENERICO
                                    </div>
                                    <div v-on:click="setInvoicePaymentMethod('banca')">BANCA</div>
                                    <div v-on:click="setInvoicePaymentMethod('cassa')">CASSA</div>
                                </div>
                            </template>
                        </transition>
                    </div>
                    <div>
                        <label>Note:</label>
                        <textarea v-model="payment.note" rows="5"></textarea>
                    </div>
                </div>
            </div>
            <div id="invoice-payment-control">
                <button v-on:click="$emit('closePaymentOptions')">ANNULLA</button>
                <button v-if="invoicePaymentToEdit === null" v-on:click="addPayment">AGGIUNGI</button>
                <button v-if="invoicePaymentToEdit !== null" v-on:click="modifyPayment">SALVA</button>
            </div>
        </div>
    </div>
</template>

<script>
    import {Italian} from "flatpickr/dist/l10n/it.js"

    export default {
        name: "InvoicePayment",
        props: ['editedInvoiceData', 'invoicePaymentToEdit'],
        data() {
            return {
                payment: {
                    date: null,
                    note: null,
                    method: null,
                    amount: null,
                },
                showInvoicePaymentMethodDropdown: false,
                dateTooltipConfig: {
                    content: 'Il campo data del movimento è obbligatorio',
                    show: false,
                    trigger: 'manual'
                },
                amountTooltipConfig: {
                    content: '',
                    show: false,
                    trigger: 'manual'
                },
                paymentDateConfig:
                    {
                        altFormat: 'd/m/Y',
                        altInput: true,
                        dateFormat: 'Y-m-d H:i:S',
                        locale: Italian,
                        monthSelectorType: 'static',
                        position: "below",
                    },
            }
        },
        watch: {
            paymentDate: function (to) {
                if (to === '') {
                    this.paymentDate = null;
                }
            }
        },
        methods: {
            addPayment: function () {
                if (this.payment.date === null) {
                    this.dateTooltipConfig.show = true;
                } else if (this.payment.amount === '0' || this.payment.amount === null) {
                    this.amountTooltipConfig.show = true;
                    this.amountTooltipConfig.content = 'Il campo importo è obbligatorio.';
                } else if (isNaN(parseFloat(this.payment.amount))) {
                    this.amountTooltipConfig.show = true;
                    this.amountTooltipConfig.content = 'Il campo importo deve essere un numero.';
                } else if (parseFloat(this.payment.amount) > this.editedInvoiceData.residuo) {
                    this.amountTooltipConfig.show = true;
                    this.amountTooltipConfig.content = 'L\'importo deve essere inferiore o uguale al residuo della fattura: ' + parseFloat(this.editedInvoiceData.residuo) + '€.';
                } else {
                    this.$emit("add", {
                        data: this.payment.date,
                        note: this.payment.note,
                        metodo: this.payment.method,
                        importo: parseFloat(this.payment.amount)
                    });
                }
            },
            modifyPayment: function () {
                let residuoNetto = this.editedInvoiceData.residuo + this.invoicePaymentToEdit.payment.importo;
                if (this.payment.date === null) {
                    this.dateTooltipConfig.show = true;
                } else if (this.payment.amount === '0' || this.payment.amount === null) {
                    this.amountTooltipConfig.show = true;
                    this.amountTooltipConfig.content = 'Il campo importo è obbligatorio.';
                } else if (isNaN(parseFloat(this.payment.amount))) {
                    this.amountTooltipConfig.show = true;
                    this.amountTooltipConfig.content = 'Il campo importo deve essere un numero.';
                } else if (parseFloat(this.payment.amount) > residuoNetto) {
                    this.amountTooltipConfig.show = true;
                    this.amountTooltipConfig.content = 'L\'importo deve essere inferiore o uguale al residuo della fattura: ' + residuoNetto.toFixed(2) + '€.';
                } else {
                    this.$emit("modify", {
                        index: this.invoicePaymentToEdit.index,
                        data: this.payment.date,
                        note: this.payment.note,
                        metodo: this.payment.method,
                        importo: parseFloat(this.payment.amount)
                    });
                }
            },
            setInvoicePaymentMethod: function (method) {
                this.showInvoicePaymentMethodDropdown = false;
                this.payment.method = method;
            }
        },
        mounted() {
            if (this.invoicePaymentToEdit !== null) {
                this.payment = {
                    date: this.invoicePaymentToEdit.payment.data,
                    note: this.invoicePaymentToEdit.payment.note,
                    method: this.invoicePaymentToEdit.payment.metodo,
                    amount: this.invoicePaymentToEdit.payment.importo,
                }
            }
        }
    }
</script>

<style lang="scss">
    #invoice-payment-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 10000;
        #InvoicePayment {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            #invoice-payment-form {
                position: relative;
                padding: 12px;
                background-color: white;
            }
            #invoice-payment-control {
                margin-top: 5px;
                button {
                    width: calc(50% - 5px);
                    border: none;
                    background-color: #248f50;
                    color: #ffffff;
                    border-radius: 0;
                    font-family: "Roboto Slab";
                    font-weight: bold;
                    padding: .6em;
                    &:first-of-type {
                        margin-right: 5px;
                    }
                    &:last-of-type {
                        margin-left: 5px;
                    }
                }

            }
        }
    }

    .custom-select {
        position: relative;
        cursor: pointer;
        display: inline-block;
        background: transparent;
        border-radius: 0;
        border: 1px solid transparent;
        border-top: none;
        box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, .12);
        font-size: 0.8em;
        font-weight: 500;
        min-height: 32px;
        height: 25px;
        width: 250px;
        padding: 0 12px 0 12px;
        font-family: "Roboto Slab";
        &:hover {
            border: 1px dashed transparent;
            border-top: none;
            box-shadow: inset 0 -2px 0 0 #248f50;
        }
        .selected-value {
            width: 100%;
            padding: 5px 0 5px 0;
            color: #999;
        }
        svg {
            float: right;
            color: #248f50;
        }

        .dropdown {
            top: 0;
            left: 0;
            z-index: 1;
            display: inline-block;
            border: 1px solid rgba(0, 0, 0, 0);
            -moz-box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
            box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
            position: absolute;
            background-color: white;
            max-height: 40vh;
            overflow-y: auto;
            width: 100%;
            > div {
                padding: 5px;
                width: 100%;
                -webkit-transition: all cubic-bezier(.19, 1, .22, 1) 1s;
                -moz-transition: all cubic-bezier(.19, 1, .22, 1) 1s;
                -o-transition: all cubic-bezier(.19, 1, .22, 1) 1s;
                transition: all cubic-bezier(.19, 1, .22, 1) 1s;
                font-family: "Roboto Slab";
                cursor: pointer;
                &:hover {
                    background-color: #248f50;
                    color: white;
                }
            }
        }

    }
</style>