<template>
    <div>
        <div id="table-toolbar">
            <div id="entriesPerPage">
                <label>Per pagina: </label>
                <div id="entriesPerPageSelect">
                    <div class="selected-value"
                         v-on:click="showLimitDropdown = !showLimitDropdown">
                        <span>{{requestParams.limit}}</span>
                        <font-awesome-icon icon="angle-down"/>
                    </div>
                    <transition name="fade">
                        <template v-if="showLimitDropdown">
                            <div class="dropdown" v-click-outside="closeLimitDropdownDropdown">
                                <div v-on:click="setLimit(15)">15</div>
                                <div v-on:click="setLimit(25)">25
                                </div>
                                <div v-on:click="setLimit(50)">50
                                </div>
                                <div v-on:click="setLimit(100)">100</div>
                            </div>
                        </template>
                    </transition>
                </div>
            </div>
        </div>
        <div id="fatture_table_container">
            <table v-if="fatture.length !== 0" id="fatture_table">
                <thead>
                <tr>
                    <th><input type="checkbox" v-model="isCheckAll" @click="checkAll"></th>
                    <th v-on:click="orderBy('TipoDocumento')">tipologia
                        <font-awesome-icon v-if="requestParams.orderBy === 'TipoDocumento'"
                                           :icon="orderIcon"/>
                    </th>
                    <th v-on:click="orderBy('Numero')">numero
                        <font-awesome-icon v-if="requestParams.orderBy === 'Numero'"
                                           :icon="orderIcon"/>
                    </th>
                    <th v-on:click="orderBy('Data')">data fattura
                        <font-awesome-icon v-if="requestParams.orderBy === 'Data'"
                                           :icon="orderIcon"/>
                    </th>
                    <th v-on:click="orderBy(soggettoDaVisualizzare+'.PartitaIva')">partita iva
                        <font-awesome-icon v-if="requestParams.orderBy === soggettoDaVisualizzare+'.PartitaIva'"
                                           :icon="orderIcon"/>
                    </th>
                    <th v-on:click="orderBy(soggettoDaVisualizzare+'.CodiceFiscale')">codice fiscale
                        <font-awesome-icon v-if="requestParams.orderBy === soggettoDaVisualizzare+'.CodiceFiscale'"
                                           :icon="orderIcon"/>
                    </th>
                    <th v-on:click="orderBy(soggettoDaVisualizzare+'.alias')">denominazione
                        <font-awesome-icon v-if="requestParams.orderBy === soggettoDaVisualizzare+'.alias'"
                                           :icon="orderIcon"/>
                    </th>
                    <th v-on:click="orderBy('dataPrevista')">data prevista {{(tipologiaDaVisualizzare ===
                        'attive')?'incasso':'pagamento'}}
                        <font-awesome-icon v-if="requestParams.orderBy === 'dataPrevista'"
                                           :icon="orderIcon"/>
                    </th>
                    <th v-on:click="orderBy('dataEffettiva')">data effettiva {{(tipologiaDaVisualizzare ===
                        'attive')?'incasso':'pagamento'}}
                        <font-awesome-icon v-if="requestParams.orderBy === 'dataEffettiva'"
                                           :icon="orderIcon"/>
                    </th>
                    <th v-on:click="orderBy('DatiPagamento.ModalitaPagamento')">modalità di pagamento
                        <font-awesome-icon v-if="requestParams.orderBy === 'DatiPagamento.ModalitaPagamento'"
                                           :icon="orderIcon"/>
                    </th>
                    <th v-on:click="orderBy('Totale')">totale
                        <font-awesome-icon v-if="requestParams.orderBy === 'Totale'"
                                           :icon="orderIcon"/>
                    </th>
                    <th v-on:click="orderBy('parziale')">parziale {{(tipologiaDaVisualizzare ===
                        'attive')?'incassato':'pagato'}}
                        <font-awesome-icon v-if="requestParams.orderBy === 'parziale'"
                                           :icon="orderIcon"/>
                    </th>
                    <th v-on:click="orderBy('residuo')">residuo da {{(tipologiaDaVisualizzare ===
                        'attive')?'incassare':'pagare'}}
                        <font-awesome-icon v-if="requestParams.orderBy === 'residuo'"
                                           :icon="orderIcon"/>
                    </th>
                    <th v-on:click="orderBy('created_at')">data importazione
                        <font-awesome-icon v-if="requestParams.orderBy === 'created_at'"
                                           :icon="orderIcon"/>
                    </th>
                </tr>
                </thead>
                <tbody>
                <RowFattura v-for="(fattura, index) in fatture"
                            :key="fattura._id"
                            :index="index"
                            v-model="checkedRows"
                            :value="fattura._id"
                            :fattura="fattura"
                            @change="updateCheckAll">
                </RowFattura>
                </tbody>
            </table>
        </div>
    </div>

</template>
<script>
    import RowFattura from './RowFattura.vue'

    export default {
        name: "Tabella",
        components: {RowFattura},
        data() {
            return {
                showLimitDropdown: false,
                isCheckAll: false,
                checkedRows: [],
            }
        },
        props: ['fatture'],
        methods: {
            updateCheckAll: function () {
                this.isCheckAll = this.fatture.length === this.checkedRows.length;
            },
            checkAll: function () {
                this.isCheckAll = !this.isCheckAll;
                this.checkedRows = [];
                if (this.isCheckAll) { // Check all
                    for (let key in this.fatture) {
                        this.checkedRows.push(this.fatture[key]._id);
                    }
                }
            },
            orderBy: function (val) {
                this.$store.commit('fatture/setOrderBy', val);
            },
            setLimit: function (setLimit) {
                this.showLimitDropdown = false;
                this.$store.commit('fatture/setParams', Object.assign({}, this.requestParams, {
                    page: 1,
                    limit: setLimit
                }));
            },
            closeLimitDropdownDropdown: function () {
                this.showLimitDropdown = false;
            }
        },
        watch: {
            checkedRows: function (val) {
                this.$store.commit('fatture/setSelectedInvoices', val)
            }
        },
        computed: {
            requestParams() {
                return this.$store.state.fatture.params;
            },
            orderIcon() {
                return (this.requestParams.order === 'DESC') ? 'angle-down' : 'angle-up';
            },
            soggettoDaVisualizzare() {
                return this.$store.state.fatture.soggettoDaVisualizzare;
            },
            tipologiaDaVisualizzare() {
                return this.$store.state.fatture.tipologiaDaVisualizzare;
            },
            lastResponse() {
                return this.$store.state.fatture.lastResponse;
            },
        }
    }
</script>

<style lang="scss">
    #table-toolbar {
        &:after {
            content: '';
            display: block;
            clear: both;
        }
        display: block;
        position: relative;
        padding: 5px;
        #recap {
            display: inline-block;
            font-family: "Roboto Slab";
            font-weight: 700;
            color: #5f6368;
            margin: 0;
        }
        #entriesPerPage {
            cursor: pointer;
            display: inline-block;
            border-radius: 0;
            font-weight: 500;
            font-family: "Roboto Slab";
            float: right;
            label {
                font-family: "Roboto Slab";
                font-weight: 700;
                color: #5f6368;
                padding-right: 5px;
                margin: 0;
            }
            #entriesPerPageSelect {
                position: relative;
                display: inline-block;
                padding: 0 2px;
                .selected-value {
                    width: 100%;
                    cursor: pointer;
                    span {
                        padding: 0 5px 0 0;
                        color: #5f6368;
                    }
                    svg {
                        color: #248f50;
                    }
                }
                .dropdown {
                    top: 0;
                    left: 0;
                    z-index: 1;
                    display: inline-block;
                    border: 1px solid rgba(0, 0, 0, 0);
                    -moz-box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
                    box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
                    position: absolute;
                    background-color: white;
                    max-height: 40vh;
                    overflow-y: auto;
                    width: 100%;
                    > div {
                        padding: 5px;
                        width: 100%;
                        -webkit-transition: all cubic-bezier(.19, 1, .22, 1) 1s;
                        -moz-transition: all cubic-bezier(.19, 1, .22, 1) 1s;
                        -o-transition: all cubic-bezier(.19, 1, .22, 1) 1s;
                        transition: all cubic-bezier(.19, 1, .22, 1) 1s;
                        font-family: "Roboto Slab";
                        cursor: pointer;
                        &:hover {
                            background-color: #248f50;
                            color: white;
                        }
                    }
                }
            }
        }
    }

    #fatture_table_container {
        width: 100%;
        position: relative;
        overflow-x: auto;
    }

    #fatture_table {
        font-size: 0.8em;
        thead {
            tr {
                border-bottom: 1px solid #92969b;
                background-color: #f0f0f0;
                font-size: 1.1em;
                color: black;

                th {
                    &:first-of-type {
                        border-right: 1px solid #248f50;
                        display: none;
                    }
                    &:hover {
                        background-color: #dddddd;
                    }
                    -webkit-transition: all cubic-bezier(.19, 1, .22, 1) 1.5s;
                    -moz-transition: all cubic-bezier(.19, 1, .22, 1) 1.5s;
                    -o-transition: all cubic-bezier(.19, 1, .22, 1) 1.5s;
                    transition: all cubic-bezier(.19, 1, .22, 1) 1.5s;
                    font-family: "Roboto Slab";
                    color: #5f6368;
                    text-align: center;
                    padding: 0.2em 25px;
                    font-weight: bold;
                    position: relative;
                    cursor: pointer;
                    svg {
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        right: 5px;
                        color: #248f50;

                    }
                }
            }
        }
        tbody {
            tr {
                border-bottom: 1px solid #dddddd;
                font-size: 0.9em;
                -webkit-transition: all cubic-bezier(.19, 1, .22, 1) 1s;
                -moz-transition: all cubic-bezier(.19, 1, .22, 1) 1s;
                -o-transition: all cubic-bezier(.19, 1, .22, 1) 1s;
                transition: all cubic-bezier(.19, 1, .22, 1) 1s;
                &:last-of-type {
                    border-bottom: none;
                }
                &:hover {
                    background-color: #dddddd;
                    //color: white;
                }
                &.saldata {
                    background-color: rgba(36, 143, 80, 0.5);
                }
                td {
                    &:first-of-type {
                        border-right: 1px solid #248f50;
                        display: none;
                    }
                    font-family: "Open Sans";
                    text-align: center;
                    padding: 0.3em;
                }
            }
        }
    }

</style>