<template>
    <div id="invoice-edit-block">
        <div id="invoice-edit-block-content">
            <font-awesome-icon icon="spinner" size="2x" spin :style="{color:'#248f50'}"/>
        </div>
    </div>
</template>
<script>
    export default {
        name: "InvoiceEditBlock",
        methods: {
            reload: function () {
                window.location.reload()
            }
        }
    }
</script>
<style scoped lang="scss">
    #invoice-edit-block {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 10;
        background-color: rgba(255, 255, 255, 0.8);
        #invoice-edit-block-content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 10;
            text-align: center;
            p {
                font-family: "Open Sans"
            }
            svg {
                margin-bottom: 15px;
            }
        }
    }
</style>