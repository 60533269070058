<template>
    <div id="fatture-block">
        <div id="fatture-block-content">
            <template v-if="FattureBlockStatus.error !== true">
                <font-awesome-icon icon="spinner" size="2x" spin :style="{color:'#248f50'}"/>
            </template>
            <template v-else-if="FattureBlockStatus.status === 400">
                <font-awesome-icon icon="exclamation-triangle" size="6x"
                                   :style="{color:'#248f50'}"/>
                <p>Errore generico</p>
                <p>Stai per essere portato a "<b :style="{color:'#248f50'}">I miei documenti</b>"</p>
            </template>
            <template v-else-if="FattureBlockStatus.status === 401">
                <font-awesome-icon icon="exclamation-triangle" size="6x"
                                   :style="{ color: '#fed91f' }"/>
                <p>La tua sessione è scaduta.</p>
                <p>Ti preghiamo di <b :style="{'text-decoration':'underline',cursor:'pointer'}" v-on:click="reload">ricaricare
                    la
                    pagina</b></p>
            </template>
            <template v-else-if="FattureBlockStatus.status === 404">
                <font-awesome-icon icon="exclamation-triangle" size="6x"
                                   :style="{ color: '#fed91f' }"/>
                <p>Quello che cerchi non è presente.</p>
                <p>Stai per essere portato a "<b :style="{color:'#248f50'}">I miei documenti</b>"</p>
            </template>
            <template v-else-if="FattureBlockStatus.status === 500">
                <font-awesome-icon icon="exclamation-triangle" size="6x"
                                   :style="{ color: '#d71920' }"/>
                <p>Il servizio non è al momento disponibile.</p>
            </template>
            <template v-else-if="FattureBlockStatus.status === 424">
                <font-awesome-icon icon="exclamation-triangle" size="6x"
                                   :style="{color: '#fed91f' }"/>
                <p>Il servizio non è al momento disponibile.</p>
            </template>
        </div>
    </div>
</template>
<script>
    export default {
        name: "FattureBlock",
        props: ['FattureBlockStatus'],
        methods: {
            reload: function () {
                window.location.reload()
            }
        }
    }
</script>
<style scoped lang="scss">
    #fatture-block {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 10;
        background-color: rgba(255, 255, 255, 0.8);
        #fatture-block-content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 10;
            text-align: center;
            p {
                font-family: "Open Sans"
            }
            svg {
                margin-bottom: 15px;
            }
        }
    }
</style>