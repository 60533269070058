<template>
    <div id="ControlCenter">
        <div id="tipologia-select">
            Tipologia Fatture:
            <Select v-if="tipologiaOptions !== null" :options="tipologiaOptions" v-model="tipologia"></Select>
        </div>
        <div id="anno-select">
            Anno Fiscale:
            <Select :options="annoOptions" v-model="anno"></Select>
        </div>
        <div id="filters-toggle-wrapper" v-on:click="$store.commit('fatture/showInvoiceFilters')">
            <b>FILTRI{{(this.$store.state.fatture.params.query !==
                undefined)?':'+this.$store.state.fatture.params.query.length:''}}</b>
            <button id="filters-toggle">
                <font-awesome-icon v-if="this.$store.state.fatture.params.query !== undefined"
                                   id="filter-alert"
                                   icon="exclamation-circle"/>
                <font-awesome-icon icon="sliders-h" size="1x"/>
            </button>
        </div>
    </div>
</template>
<script>
    import Select from '../ui/Select'

    export default {
        name: "ControlCenter",
        data() {
            return {
                tipologia: null,
                anno: null,
                tipologiaOptions: [],
                annoOptions: [],
                searchTooltipConfig: {
                    content: 'CERCA'
                },
                filterTooltipConfig: {
                    content: 'FILTRI',
                },
                resetSearchTooltipConfig: {
                    content: 'CANCELLA'
                }
            }
        }, components: {Select},
        watch: {
            anno(to, from) {
                if (to !== from && from !== null) {
                    let newParams = Object.assign({}, this.$store.state.fatture.params, {
                        anno: to,
                        page: 1
                    });
                    delete newParams.query;
                    this.$store.commit('fatture/setParams', newParams);
                }
            },
            tipologia(to, from) {
                if (to !== from && from !== null) {
                    let newParams = Object.assign({}, this.$store.state.fatture.params, {
                        tipologia: to,
                        page: 1
                    });
                    delete newParams.query;
                    this.$store.commit('fatture/setParams', newParams);
                }
            }
        },
        created() {
            this.anno = this.$store.state.fatture.params.anno;
            this.tipologia = this.$store.state.fatture.params.tipologia;
            let defaultTipologiaOptions = [{
                value: "attive",
                name: "attive",
                selected: false
            }, {
                value: "passive",
                name: "passive",
                selected: false
            }];
            let index = this._.findIndex(defaultTipologiaOptions, {value: this.tipologia});
            defaultTipologiaOptions[index].selected = true;
            this.tipologiaOptions = defaultTipologiaOptions;
            let defaultAnnoOptions = [];
            for (let counter = 2019; counter <= this.moment().year(); counter++) {
                defaultAnnoOptions.push({
                    value: parseInt(counter),
                    name: parseInt(counter),
                    selected: (counter === parseInt(this.anno))
                })
            }
            this.annoOptions = defaultAnnoOptions;
        }
    }
</script>

<style scoped lang="scss">
    #filters-toggle-wrapper {
        cursor: pointer;
        -webkit-transition: all cubic-bezier(.19, 1, .22, 1) 1.5s;
        -moz-transition: all cubic-bezier(.19, 1, .22, 1) 1.5s;
        -o-transition: all cubic-bezier(.19, 1, .22, 1) 1.5s;
        transition: all cubic-bezier(.19, 1, .22, 1) 1.5s;
        padding-left: 1em;
        &:hover {
            background-color: #dddddd;
        }
        b {
            font-family: "Roboto Slab";
            color: #5f6368;
        }
        float: right;
        position: relative;
        #filters-toggle {
            font-size: 2rem;
            color: #248f50;
            border: none;
            padding: 10px;
            background-color: transparent;
            position: relative;
            #filter-alert {
                width: 15px;
                height: 15px;
                position: absolute;
                right: 3px;
                top: 0;
                color: red;
            }
        }
    }

    #ControlCenter {
        &:after {
            clear: both;
            content: "";
            display: block;
        }
        font-family: "Roboto Slab";
        background-color: #f0f0f0;
        padding: 5px;
        #tipologia-select,
        #anno-select {
            padding: 5px;
            color: #5f6368;
            font-weight: bold;
            display: inline-block;
        }
    }
</style>