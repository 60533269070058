<template>
  <div id="filters-overlay">
    <div id="filters-container">
      <div id="filters" style="background-color:transparent">
        <div id="filtersOptionsControl">
          <div id="closeFiltersOptions"
               v-on:click="$store.commit('fatture/showInvoiceFilters')" key="closeAll">
            <span>CHIUDI</span>
            <font-awesome-icon icon="times"/>
          </div>
        </div>
        <div id="filtersWrapper">
          <div>
            <label>{{ (soggettoDaVisualizzare === 'CedentePrestatore') ? 'Pagata' : 'Incassata' }}</label>
            <div class="custom-select">
              <div class="selected-value"
                   v-on:click="showInvoicePaymentStateDropdown = !showInvoicePaymentStateDropdown">
                <template v-if="invoice.saldata === '*'">
                  <span>Tutte</span>
                </template>
                <template v-else>
                  <span>{{ (invoice.saldata) ? 'SI' : 'NO' }}</span>
                </template>
                <font-awesome-icon icon="angle-down"/>
              </div>
              <transition name="fade">
                <template v-if="showInvoicePaymentStateDropdown">
                  <div class="dropdown" v-click-outside="clickOutsideSelects">
                    <div v-on:click="setInvoicePaymentState('*')">Tutte</div>
                    <div v-on:click="setInvoicePaymentState(true)">SI</div>
                    <div v-on:click="setInvoicePaymentState(false)">NO</div>
                  </div>
                </template>
              </transition>
            </div>
          </div>
          <div>
            <label>Tipologia Documento</label>
            <div class="custom-select">
              <div class="selected-value"
                   v-on:click="showInvoiceTypologyDropdown = !showInvoiceTypologyDropdown">
                <template v-if="invoice.TipoDocumento === '*'">
                  <span>Tutte</span>
                </template>
                <template v-else>
                  <span>{{ $store.getters['fatture/getTipoDocumento'](invoice.TipoDocumento).value }}</span>
                </template>
                <font-awesome-icon icon="angle-down"/>
              </div>
              <transition name="fade">
                <template v-if="showInvoiceTypologyDropdown">
                  <div class="dropdown" v-click-outside="clickOutsideSelects">
                    <div v-on:click="setInvoiceTypology('*')">Tutte</div>
                    <div v-for="tipodocumento  in $store.getters['fatture/TipiDocumento']"
                         v-on:click="setInvoiceTypology(tipodocumento.code)"
                         :key="tipodocumento.code">
                      {{ tipodocumento.value }}
                    </div>
                  </div>
                </template>
              </transition>
            </div>
          </div>
          <div>
            <label for="invoice-number">Numero Della Fattura</label>
            <input class="text-filter" id="invoice-number" type="text" v-model="invoice.Numero"
                   placeholder="Inserire il numero Della Fattura">
          </div>
          <div>
            <label for="invoice-vat-number">Partita IVA {{
                (soggettoDaVisualizzare ===
                    'CedentePrestatore') ? 'fornitore' : 'cliente'
              }}</label>
            <input class="text-filter" id="invoice-vat-number" type="text" v-model="invoice.partitaIva"
                   maxlength="11"
                   placeholder="Inserire la Partita IVA">
          </div>
          <div>
            <label for="invoice-fiscal-code">Codice Fiscale {{
                (soggettoDaVisualizzare ===
                    'CedentePrestatore') ? 'fornitore' : 'cliente'
              }}</label>
            <input class="text-filter" id="invoice-fiscal-code" type="text"
                   v-model="invoice.codiceFiscale"
                   placeholder="Inserire il Codice Fiscale">
          </div>
          <div>
            <label for="invoice-denomination">Denominazione {{
                (soggettoDaVisualizzare ===
                    'CedentePrestatore') ? 'fornitore' : 'cliente'
              }}</label>
            <input class="text-filter" id="invoice-denomination" type="text"
                   v-model="invoice.denominazione"
                   placeholder="Inserire la Denominazione">
          </div>
          <div>
            <label>Modalità di pagamento</label>
            <div class="custom-select">
              <div class="selected-value"
                   v-on:click="showInvoicePaymentModalityDropdown = !showInvoicePaymentModalityDropdown">
                <template v-if="invoice.ModalitaPagamento === '*'">
                  <span>Tutte</span>
                </template>
                <template v-else>
                  <span>{{ $store.getters['fatture/getModalitaPagamento'](invoice.ModalitaPagamento).value }}</span>
                </template>
                <font-awesome-icon icon="angle-down"/>
              </div>
              <transition name="fade">
                <template v-if="showInvoicePaymentModalityDropdown">
                  <div id="invoice-payment-modality-dropdown" class="dropdown" v-click-outside="clickOutsideSelects">
                    <div v-on:click="setInvoicePaymentModality('*')">Tutte</div>
                    <div v-for="modalitaPagamento  in $store.getters['fatture/ModalitaPagamento']"
                         v-on:click="setInvoicePaymentModality(modalitaPagamento.code)"
                         :key="modalitaPagamento.code">
                      {{ modalitaPagamento.value }}
                    </div>
                  </div>
                </template>
              </transition>

            </div>
          </div>
          <div>
            <label class="master-label">Data della fattura</label><br>
            <label for="invoice-date-from">Da: </label>
            <flat-pickr
                v-on:click="$event.preventDefault()"
                v-model="invoice.dataFatturaFrom"
                :config="dataFatturaFromConfig"
                class="text-filter"
                placeholder="Selezionare la data minima delle fatture"
                id="invoice-date-from"
            >
            </flat-pickr>
            <br>
            <label for="invoice-date-to">A: </label>
            <flat-pickr
                v-on:click="$event.preventDefault()"
                v-model="invoice.dataFatturaTo"
                :config="dataFatturaToConfig"
                class="text-filter"
                placeholder="Selezionare la data minima delle fatture"
                id="invoice-date-to"
            >
            </flat-pickr>
          </div>
          <div><label class="master-label">Data scadenza del pagamento</label><br>
            <label for="invoice-expire-date-from">Da: </label>
            <flat-pickr
                v-model="invoice.dataScadenzaFrom"
                :config="dataScadenzaFromConfig"
                class="text-filter"
                placeholder="Selezionare la data minima di scadenza del pagamento"
                id="invoice-expire-date-from"
            >
            </flat-pickr>
            <br>
            <label for="invoice-expire-date-to">A: </label>
            <flat-pickr
                v-model="invoice.dataScadenzaTo"
                :config="dataScadenzaToConfig"
                class="text-filter"
                placeholder="Selezionare la data massima di scadenza del pagamento"
                id="invoice-expire-date-to"
            >
            </flat-pickr>
          </div>
          <div>
            <label class="master-label">Importo Totale Fattura</label><br>
            <label for="invoice-total-from">Da: </label>
            <input class="text-filter" id="invoice-total-from" type="text" v-model="invoice.totaleFrom"
                   placeholder="Inserire l'importo totale minimo"><br>
            <label for="invoice-total-to">A: </label>
            <input class="text-filter" id="invoice-total-to" type="text" v-model="invoice.totaleTo"
                   placeholder="Inserire l'importo totale massimo">
          </div>
        </div>
        <div id="filtersActions">
          <div v-on:click="applyFilters"><span>Applica Filtri</span></div>
          <div v-on:click="resetFilters"><span>Resetta Filtri</span></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Italian} from "flatpickr/dist/l10n/it.js"

export default {
  name: "Filters",
  computed: {
    fattureMapping() {
      return this.$store.state.fatture.fattureMapping;
    },
    soggettoDaVisualizzare() {
      return this.$store.state.fatture.soggettoDaVisualizzare;
    }
  },
  data() {
    return {
      tipologiaFattureDaFiltrare: null,
      currentYear: '',
      showInvoicePaymentStateDropdown: false,
      showInvoiceTypologyDropdown: false,
      showInvoicePaymentModalityDropdown: false,
      showInvoicePaymentConditionDropdown: false,
      dataFatturaFromConfig: {
        altFormat: 'd/m/Y',
        altInput: true,
        dateFormat: 'Y-m-d',
        locale: Italian,
        static: true,
        monthSelectorType: 'static',
        minDate: '2021-01-01',
        maxDate: '2021-12-31',
        position: "above",
        onChange: this.flatPickrChangeHandler
      },
      dataFatturaToConfig: {
        altFormat: 'd/m/Y',
        altInput: true,
        dateFormat: 'Y-m-d',
        locale: Italian,
        static: true,
        monthSelectorType: 'static',
        minDate: '2021-01-01',
        maxDate: '2021-12-31',
        position: "above",
        onChange: this.flatPickrChangeHandler
      },
      dataScadenzaFromConfig: {
        altFormat: 'd/m/Y',
        altInput: true,
        dateFormat: 'Y-m-d',
        locale: Italian,
        static: true,
        monthSelectorType: 'static',
        minDate: '2021-01-01',
        maxDate: '2021-12-31',
        position: "above",
        onChange: this.flatPickrChangeHandler
      },
      dataScadenzaToConfig: {
        altFormat: 'd/m/Y',
        altInput: true,
        dateFormat: 'Y-m-d',
        locale: Italian,
        static: true,
        monthSelectorType: 'static',
        minDate: '2021-01-01',
        maxDate: '2021-12-31',
        position: "above",
        onChange: this.flatPickrChangeHandler
      },
      invoice: {
        saldata: '*',
        TipoDocumento: '*',
        Numero: '',
        partitaIva: '',
        codiceFiscale: '',
        denominazione: '',
        ModalitaPagamento: '*',
        CondizioniPagamento: '*',
        dataFatturaFrom: null,
        dataFatturaTo: null,
        dataScadenzaFrom: null,
        dataScadenzaTo: null,
        totaleFrom: '',
        totaleTo: ''
      }
    }
  },
  methods: {
    flatPickrChangeHandler(selectedDates, dateStr, istance) {
      let currentDatePickerId = istance.element.id;
      console.log(selectedDates,dateStr,istance);
      switch (currentDatePickerId) {
        case 'invoice-date-from':
          if (dateStr === '') {
            this.dataFatturaToConfig.minDate = this.moment().year(this.currentYear).startOf('year').format('YYYY-MM-DD');
          } else {
            this.dataFatturaToConfig.minDate = dateStr;
          }
          break;
        case 'invoice-date-to':
          if (dateStr === '') {
            this.dataFatturaFromConfig.maxDate = this.moment().year(this.currentYear).endOf('year').format('YYYY-MM-DD');
          } else {
            this.dataFatturaFromConfig.maxDate = dateStr;
          }
          break;
        case 'invoice-expire-date-from':
          if (dateStr === '') {
            this.dataScadenzaToConfig.minDate = this.moment().year(this.currentYear).startOf('year').format('YYYY-MM-DD');
          } else {
            this.dataScadenzaToConfig.minDate = dateStr;
          }
          break;
        case 'invoice-expire-date-to':
          if (dateStr === '') {
            this.dataScadenzaFromConfig.maxDate = this.moment().year(this.currentYear).endOf('year').format('YYYY-MM-DD');
          } else {
            this.dataScadenzaFromConfig.maxDate = dateStr;
          }
          break;
      }
    },
    clickOutsideSelects() {
      this.showInvoicePaymentStateDropdown = false;
      this.showInvoiceTypologyDropdown = false;
      this.showInvoicePaymentModalityDropdown = false;
      this.showInvoicePaymentConditionDropdown = false;
    },
    setInvoicePaymentState: function (value) {
      this.invoice.saldata = value;
      this.showInvoicePaymentStateDropdown = false;
    },
    setInvoiceTypology: function (value) {
      this.invoice.TipoDocumento = value;
      this.showInvoiceTypologyDropdown = false;
    },
    setInvoicePaymentModality: function (value) {
      this.invoice.ModalitaPagamento = value;
      this.showInvoicePaymentModalityDropdown = false;
    },
    applyFilters: function () {
      let newParams = this.$store.state.fatture.params;
      newParams.query = [];
      if (this.invoice.saldata !== '*') {
        newParams.query.push({
          param: 'saldata',
          operator: '=',
          value: this.invoice.saldata
        });
      }
      if (this.invoice.TipoDocumento !== '*') {
        newParams.query.push({
          param: 'TipoDocumento',
          operator: '=',
          value: this.invoice.TipoDocumento
        });
      }
      if (this.invoice.ModalitaPagamento !== '*') {
        newParams.query.push({
          param: 'DatiPagamento.ModalitaPagamento',
          operator: '=',
          value: this.invoice.ModalitaPagamento
        });
      }
      if (this.invoice.Numero !== '') {
        newParams.query.push({
          param: 'Numero',
          operator: 'like',
          value: this.invoice.Numero
        });
      }
      if (this.invoice.partitaIva !== '') {
        newParams.query.push({
          param: this.soggettoDaVisualizzare + '.PartitaIva',
          operator: 'like',
          value: this.invoice.partitaIva
        });
      }
      if (this.invoice.codiceFiscale !== '') {
        newParams.query.push({
          param: this.soggettoDaVisualizzare + '.CodiceFiscale',
          operator: 'like',
          value: this.invoice.codiceFiscale
        });
      }
      if (this.invoice.denominazione !== '') {
        newParams.query.push({
          param: this.soggettoDaVisualizzare + '.alias',
          operator: 'like',
          value: this.invoice.denominazione
        });
      }
      if (this.invoice.dataFatturaFrom !== null) {
        newParams.query.push({
          param: 'Data',
          operator: '>=',
          value: this.invoice.dataFatturaFrom
        });
      }
      if (this.invoice.dataFatturaTo !== null) {
        newParams.query.push({
          param: 'Data',
          operator: '<=',
          value: this.invoice.dataFatturaTo
        });
      }
      if (this.invoice.dataScadenzaFrom !== null) {
        newParams.query.push({
          param: 'DatiPagamento.DataScadenzaPagamento',
          operator: '>=',
          value: this.invoice.dataScadenzaFrom
        });
      }
      if (this.invoice.dataScadenzaTo !== null) {
        newParams.query.push({
          param: 'DatiPagamento.DataScadenzaPagamento',
          operator: '<=',
          value: this.invoice.dataScadenzaTo
        });
      }
      if (this.invoice.totaleFrom !== '') {
        newParams.query.push({
          param: 'Totale',
          operator: '>',
          value: this.invoice.totaleFrom
        });
      }
      if (this.invoice.totaleTo !== '') {
        newParams.query.push({
          param: 'Totale',
          operator: '<',
          value: this.invoice.totaleTo
        });
      }
      if (newParams.query.length === 0) {
        delete newParams.query;
      }
      this.$store.commit('fatture/setParams', Object.assign({}, newParams));
      this.$store.commit('fatture/showInvoiceFilters')
    },
    resetFilters: function () {
      let newList = this.$store.state.fatture.params;
      delete newList.query;
      this.general = {
        name: ''
      };
      this.invoice = {
        saldata: '*',
        TipoDocumento: '*',
        Numero: '',
        partitaIva: '',
        codiceFiscale: '',
        denominazione: '',
        ModalitaPagamento: '*',
        CondizioniPagamento: '*',
        dataFatturaFrom: null,
        dataFatturaTo: null,
        dataScadenzaFrom: null,
        dataScadenzaTo: null,
        totaleFrom: '',
        totaleTo: ''
      };
    }
  },
  mounted() {
    let currentQuery = this.$store.state.fatture.params.query;
    this.tipologiaFattureDaFiltrare = this.$store.state.fatture.params.tipologiaFattura;
    this.currentYear = this.$store.state.fatture.params.anno;
    let startOfInvoiceYear = this.moment().year(this.currentYear).startOf('year').format('YYYY-MM-DD');
    let endOfInvoiceYear = this.moment().year(this.currentYear).endOf('year').format('YYYY-MM-DD');

    this.dataScadenzaToConfig.minDate = startOfInvoiceYear;
    this.dataScadenzaFromConfig.minDate = startOfInvoiceYear;
    this.dataFatturaToConfig.minDate = startOfInvoiceYear;
    this.dataFatturaFromConfig.minDate = startOfInvoiceYear;

    this.dataScadenzaToConfig.maxDate = endOfInvoiceYear;
    this.dataScadenzaFromConfig.maxDate = endOfInvoiceYear;
    this.dataFatturaToConfig.maxDate = endOfInvoiceYear;
    this.dataFatturaFromConfig.maxDate = endOfInvoiceYear;

    if (currentQuery !== undefined) {
      currentQuery.forEach(singleQueryParameter => {
        if (singleQueryParameter.param === 'Data' && singleQueryParameter.operator === '>=') {
          this.invoice.dataFatturaFrom = singleQueryParameter.value;
        } else if (singleQueryParameter.param === 'Data' && singleQueryParameter.operator === '<=') {
          this.invoice.dataFatturaTo = singleQueryParameter.value;
        } else if (singleQueryParameter.param === 'DatiPagamento.DataScadenzaPagamento' && singleQueryParameter.operator === '>=') {
          this.invoice.dataScadenzaFrom = singleQueryParameter.value;
        } else if (singleQueryParameter.param === 'DatiPagamento.DataScadenzaPagamento' && singleQueryParameter.operator === '<=') {
          this.invoice.dataScadenzaTo = singleQueryParameter.value;
        } else if (singleQueryParameter.param === 'DatiPagamento.ModalitaPagamento') {
          this.invoice.ModalitaPagamento = singleQueryParameter.value;
        } else if (singleQueryParameter.param === 'DatiPagamento.CondizioniPagamento') {
          this.invoice.CondizioniPagamento = singleQueryParameter.value;
        } else if (singleQueryParameter.param === 'Totale' && singleQueryParameter.operator === '>') {
          this.invoice.totaleFrom = singleQueryParameter.value;
        } else if (singleQueryParameter.param === 'Totale' && singleQueryParameter.operator === '<') {
          this.invoice.totaleTo = singleQueryParameter.value;
        } else if (singleQueryParameter.param === this.soggettoDaVisualizzare + '.CodiceFiscale') {
          this.invoice.codiceFiscale = singleQueryParameter.value;
        } else if (singleQueryParameter.param === this.soggettoDaVisualizzare + '.PartitaIva') {
          this.invoice.partitaIva = singleQueryParameter.value;
        } else if (singleQueryParameter.param === this.soggettoDaVisualizzare + '.alias') {
          this.invoice.denominazione = singleQueryParameter.value;
        }
        {
          this.invoice[singleQueryParameter.param] = singleQueryParameter.value;
        }
      })
    }
  }
}
</script>

<style lang="scss">

#filters-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10000;

  #filters-container {
    position: absolute;
    width: 80%;
    max-width: 768px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: transparent;
    -webkit-transition: all ease-out 1s;
    -moz-transition: all ease-out 1s;
    -o-transition: all ease-out 1s;
    transition: all ease-out 1s;
  }
}

.custom-select {
  position: relative;
  cursor: pointer;
  display: inline-block;
  background: transparent;
  border-radius: 0;
  border: 1px solid transparent;
  border-top: none;
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, .12);
  font-size: 0.8em;
  font-weight: 500;
  min-height: 32px;
  height: 25px;
  width: 250px;
  padding: 0 12px 0 12px;
  font-family: "Roboto Slab";

  &:hover {
    border: 1px dashed transparent;
    border-top: none;
    box-shadow: inset 0 -2px 0 0 #248f50;
  }

  .selected-value {
    width: 100%;
    padding: 5px 0 5px 0;
    color: #999;
  }

  svg {
    float: right;
    color: #248f50;
  }

  .dropdown {
    top: 0;
    left: 0;
    z-index: 1;
    display: inline-block;
    border: 1px solid rgba(0, 0, 0, 0);
    -moz-box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
    box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
    position: absolute;
    background-color: white;
    max-height: 40vh;
    overflow-y: auto;
    width: 100%;

    > div {
      padding: 5px;
      width: 100%;
      -webkit-transition: all cubic-bezier(.19, 1, .22, 1) 1s;
      -moz-transition: all cubic-bezier(.19, 1, .22, 1) 1s;
      -o-transition: all cubic-bezier(.19, 1, .22, 1) 1s;
      transition: all cubic-bezier(.19, 1, .22, 1) 1s;
      font-family: "Roboto Slab";
      cursor: pointer;

      &:hover {
        background-color: #248f50;
        color: white;
      }
    }
  }

}

#filters {
  #filtersOptionsControl {
    background-color: #248f50;

    &:after {
      content: '';
      display: block;
      clear: both;
    }

    #closeFiltersOptions {
      color: #ffffff;
      padding: 10px;
      font-family: "Roboto Slab";
      font-weight: bold;
      cursor: pointer;
      float: right;

      span {
        padding-right: 10px;
      }
    }
  }

  #filtersWrapper {
    background-color: #ffffff;
    margin: 5px 0;
    padding: 24px;
    max-height: 80vh;
    overflow-y: auto;

    > div {
      padding: 5px 0;

      label {
        font-size: 0.85em;
        color: #5f6368;
        font-family: "Roboto Slab";
        width: 190px;
      }

      label.master-label {
        width: 100%;
      }

      .text-filter:focus, .text-filter:hover {
        border: 1px dashed transparent;
        box-shadow: inset 0 -2px 0 0 #248f50;
        border-top: none;
      }

      .text-filter:focus {
        outline: none;
      }

      .text-filter {
        color: #202124;
        font-size: 13px;
        font-weight: 500;
        background: transparent;
        border-radius: 0;
        border: 1px solid transparent;
        border-top: none;
        box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, .12);
        box-sizing: border-box;
        display: inline-block;
        overflow: hidden;
        padding: 0 12px 2px;
        vertical-align: middle;
        min-height: 32px;
        width: 458px;
        height: 25px;
        line-height: normal;
        white-space: nowrap;
        font-family: "Roboto Slab";
      }
    }
  }

  #filtersActions {
    &:after {
      content: '';
      display: block;
      clear: both;
    }

    background-color: #248f50;

    div {
      padding: 10px;
      display: block;
      text-align: center;
      cursor: pointer;
      float: left;

      span {
        color: white;
        font-family: "Roboto Slab";
        font-weight: bold;
        text-transform: uppercase;
      }
    }
  }
}

.numInputWrapper {
  display: none !important;
}

.flatpickr-months {
  .flatpickr-month {
    border-radius: 0 !important;
    font-family: "Roboto Slab" !important;
    background: #248f50 !important;
  }
}

.flatpickr-calendar {
  &.static {
    bottom: calc(100% + 2px) !important;
    top: unset !important;
  }
}

.flatpickr-weekdays {
  background: #248f50 !important;

  .flatpickr-weekdaycontainer {
    .flatpickr-weekday {
      color: #ffffff !important;
      font-family: "Roboto Slab" !important;
      background: #248f50 !important;
    }
  }
}

.flatpickr-day {
  &.selected {
    background: #248f50 !important;
  }
}

</style>