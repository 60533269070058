<template>
    <tr v-bind:key="fattura._id" v-on:click="$store.commit('fatture/showInvoiceOptions',index)" v-bind:class="{ saldata: fattura.saldata }">
        <td v-on:click="$event.stopPropagation()">
            <input type="checkbox" :checked="isChecked" @change="change">
        </td>
        <td>
            {{$store.getters['fatture/getTipoDocumento'](fattura.TipoDocumento).value}}
        </td>
        <td>
            {{fattura.Numero}}
        </td>
        <td>
            {{moment(fattura.Data).format('L')}}
        </td>
        <td>
            {{fattura[soggettoDaVisualizzare].PartitaIva}}
        </td>
        <td>
            {{fattura[soggettoDaVisualizzare].CodiceFiscale}}
        </td>
        <td>
            {{fattura[soggettoDaVisualizzare].alias}}
        </td>
        <td>
            {{(fattura.dataPrevista !== null)?moment(fattura.dataPrevista).format('L'):'nd'}}
        </td>
        <td>
            {{(fattura.dataEffettiva !== null)?moment(fattura.dataEffettiva).format('L'):'nd'}}
        </td>
        <td>
            {{(fattura.DatiPagamento!== null)?$store.getters['fatture/getModalitaPagamento'](fattura.DatiPagamento.ModalitaPagamento).value
            :'nd'}}
        </td>
        <td>
            {{fattura.Totale.toFixed(2)}}
        </td>
        <td>
            {{fattura.parziale.toFixed(2)}}
        </td>
        <td>
            {{fattura.residuo.toFixed(2)}}
        </td>
        <td>
            {{moment(fattura.created_at).format('L')}}
        </td>
    </tr>
</template>
<script>
    export default {
        name: "RowFattura",
        model: {
            prop: "checked",
            event: "change"
        },
        props: ['fattura', 'checked', 'value', 'index'],
        computed: {
            isChecked() {
                return this.checked.includes(this.value);
            },
            fattureMapping() {
                return this.$store.state.fatture.fattureMapping;
            },
            soggettoDaVisualizzare() {
                return this.$store.state.fatture.soggettoDaVisualizzare;
            },
            tipologiaDaVisualizzare() {
                return this.$store.state.fatture.tipologiaDaVisualizzare;
            }
        },
        methods: {
            change() {
                const checked = this.checked.slice();
                const found = checked.indexOf(this.value);
                if (found !== -1) {
                    checked.splice(found, 1);
                } else {
                    checked.push(this.value);
                }
                this.$emit("change", checked);
            }
        }
    }
</script>

<style scoped lang="scss">
    tr {
        cursor: pointer
    }
</style>