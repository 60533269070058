<template>
    <div class="select">
        <div class="select-value" v-on:click="showDropDown = !showDropDown">
            {{selectedOption.name}}<font-awesome-icon icon="angle-down"/>
        </div>
        <transition name="fade">
            <div v-if="showDropDown" class="select-dropdown" v-click-outside="close">
                <div v-for="option in options" :key="option.value" v-on:click="setSelectedOption(option)">
                    {{option.name}}
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
    export default {
        name: "Select",
        props: ['options', 'value'],
        data() {
            return {
                showDropDown: false,
                selectedOption: {}
            }
        },
        methods: {
            setSelectedOption: function (option) {
                this.showDropDown = false;
                this.selectedOption = option;
                this.$emit('input', option.value)
            },
            close: function () {
                if (this.showDropDown) {
                    this.showDropDown = false;
                }
            }
        },
        mounted() {
            this.selectedOption = this._.find(this.options, function (option) {
                return option.selected === true;
            });
        }
    }
</script>

<style scoped lang="scss">
    .select {
        position: relative;
        cursor: pointer;
        display: inline-block;
        background: transparent;
        border-radius: 0;
        border: 1px solid transparent;
        border-top: none;
        box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, .12);
        font-size: 1em;
        font-weight: 500;
        min-height: 32px;
        height: 25px;
        padding: 0 12px 0 12px;
        font-family: "Roboto Slab";
        &:hover {
            border: 1px dashed transparent;
            border-top: none;
            box-shadow: inset 0 -2px 0 0 #248f50;
        }
        .select-value {
            width: 100%;
            padding: 5px 0 5px 0;
            color: #248f50;
            text-transform: uppercase;
            svg {
                color: #248f50;
                margin-left: 5px;
            }
        }
        .select-dropdown {
            top: 0;
            left: 0;
            z-index: 1;
            display: inline-block;
            -moz-box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
            box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
            position: absolute;
            background-color: white;
            max-height: 40vh;
            overflow-y: auto;
            width: 100%;
            > div {
                text-align: center;
                padding: 5px;
                width: 100%;
                -webkit-transition: all cubic-bezier(.19, 1, .22, 1) 1s;
                -moz-transition: all cubic-bezier(.19, 1, .22, 1) 1s;
                -o-transition: all cubic-bezier(.19, 1, .22, 1) 1s;
                transition: all cubic-bezier(.19, 1, .22, 1) 1s;
                font-family: "Roboto Slab";
                cursor: pointer;
                &:hover {
                    background-color: #248f50;
                    color: white;
                }
            }
        }
    }
</style>